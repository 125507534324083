import dk from '@norsktest/dkdj';

export function VotAgreements(id, datasource_url) {
    return dk.table.ResultSet.create_on(id, {
        dataset: dk.data.Data.create({
            pagesize: 100,
            datasource: dk.data.AjaxDataSource.create({
                url: datasource_url
            })
        }),
        construct_table: function (location, downloadwidget, ds) {
            return dk.DataTable.create_on(location, {
                classes: ['table table-bordered table-hover table-condensed'],
                table_data: ds,
                columns: {
                    owner: {
                        label: 'Firma',
                        format: function (val, rec) {
                            if (rec.owner) {
                                return `${rec.owner[0].name}`;
                            }
                        }
                    },
                    name: {label: 'Navn'},
                    secure_url: {
                        label: 'Avtale',
                        format: function (val, rec) {
                            if (rec.access_document) {
                                return `<a href="${val}">${rec.filename}</a>`;
                            } else {
                                return rec.filename;
                            }
                        }
                    },
                    status: {label: 'Status'},
                    sign_by: {label: 'Signerer firma'},
                    sign_by_email: {label: 'E-post signerer'},
                    created: {
                        label: 'Opprettet',
                        format: dk.format.no_date
                    },
                    signed: {
                        label: 'Signert',
                        format: dk.format.no_date
                    }
                }
            });
        },
        command_buttons: {
            add: {
                show: false,
            },
            csv: {
                show: true,
                filename: 'votagreements.csv'
            }
        }
    });
}
