import dk from "@norsktest/dkdj";

export function faktaboks() {
    dk.$.fn.faktaboks = function () {

        this.each(function (index) {
            const $this = dk.$(this);
            const boxClass = `faktaboks-${index}`;
            $this.addClass(boxClass);
            const btn = dk.$(`
                <div class="faktaboks-toggle ${boxClass}">
                    <div class="faktaboks-toggle-text">Vis mer</div>
                    <div class="faktaboks-btn-wrapper">
                        <button class="faktaboks-btn">
                            <dk-icon value="chevron-down"></dk-icon>
                        </button>
                    </div>
                </div>`);
            btn.on('click', function () {
                $this.toggleClass('faktaboks-show');
                dk.$(`.${boxClass} .faktaboks-toggle-text`).toggleClass('faktaboks-toggle-text-hide');
                btn.find('dk-icon').attr('rotate', 180);    // btn.find('dk-icon')[0].rotate = 180;
            });
            $this.after(btn);
        });
    };
}
