import {faktaboks} from "./faktaboks-plugin";
import {VotAgreements} from "./vot-agreements";
import {Manuals} from "./manuals";
import {mark_all, pusher_client, RegisterIdd, submit_marked_users} from "./register-idd";

faktaboks();

const finautapp = {
    // insert exported names here
    VotAgreements,
    Manuals,
    RegisterIdd,
    mark_all,
    submit_marked_users,
    pusher_client
};

export default finautapp;
