import dk from '@norsktest/dkdj';

class FilterPanel extends dk.Panel {

    constructor(...args) {
        super({
            direction: 'left',
            title: 'Filter',
            dataset: null,
        }, ...args);
    }

    construct_panel_body(body) {
        const self = this;
        const filterbox = $('<div/>');
        body.append(filterbox);

        this.datafilter = dk.filter.DataFilter.create_on(filterbox, {
            structure: {
                content: {}
            },
            dataset: self.dataset,
            filters: {
                usertype: {
                    label: 'Brukertyper',
                }
            }
        });
        return body;
    }
}


export function Manuals(id, datasource_url) {
    return dk.table.ResultSet.create_on(id, {
        dataset: dk.data.Data.create({
            pagesize: 100,
            datasource: dk.data.AjaxDataSource.create({
                url: datasource_url
            })
        }),
        construct_filter: function (location, dataset) {
            return FilterPanel.create(location, {
                dataset: dataset
            });
        },
        construct_table: function (location, downloadwidget, ds) {
            return dk.DataTable.create_on(location, {
                classes: ['table table-bordered table-hover table-condensed'],
                table_data: ds,
                columns: {
                    name: {
                        label: 'Navn',
                        format: function (val, rec) {
                            return `<a href="edit/${rec.pk}/">${val}</a>`;
                        }
                    },
                    description: {
                        label: 'Beskrivelse',
                    },
                    usertype: {
                        label: 'Brukertype',
                        format: function (val, rec) {
                            return rec.usertype.full_name;
                        }
                    },
                    url: {
                        label: 'Link',
                    },
                    delete: {
                        label: 'Slett',
                        format: function (val, rec) {
                            return `<a href="delete/${rec.pk}/" class="btn btn-danger btn-xs" onclick="return confirm('Er du sikker på at du ønsker å slette denne oppføringen?')">Slett</a>`;
                        }
                    }
                }
            });
        },
        command_buttons: {
            add: {
                show: true,
                url: 'add/',
            },
            csv: {
                show: true,
                filename: 'hjelp_og_veiledning.csv'
            }
        }
    });
}
